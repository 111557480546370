html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  overscroll-behavior: contain; }

html,
body,
div,
article,
section,
main,
footer,
header,
form,
fieldset,
legend,
pre,
code,
p,
a,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
li,
dl,
dt,
dd,
textarea,
input[type='email'],
input[type='number'],
input[type='password'],
input[type='tel'],
input[type='text'],
input[type='url'],
.border-box {
  box-sizing: border-box; }

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
fieldset {
  margin: 0;
  padding: 0; }

button,
input,
optgroup,
select,
textarea {
  font-size: 100%;
  margin: 0; }

input,
select,
textarea {
  vertical-align: baseline;
  *vertical-align: middle; }

article,
footer,
header,
nav,
section {
  display: block; }

li {
  list-style: none; }

img {
  border-style: none;
  vertical-align: middle; }

button,
input {
  line-height: normal;
  overflow: visible; }

button,
[type='button'],
[type='reset'],
[type='submit'] {
  cursor: pointer;
  -webkit-appearance: button; }

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box; }

[type='search'] {
  -webkit-appearance: textfield;
  box-sizing: content-box; }

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td {
  vertical-align: top; }

div,
article,
section,
header,
footer,
nav,
li {
  position: relative; }

.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  min-height: 1px;
  padding: 0 12px;
  position: relative; }

.col-section {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap; }

.col-no-margin {
  margin: 0 -12px; }

.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  float: left; }

.col-xs-12 {
  width: 100%; }

.col-xs-11 {
  width: 91.66666667%; }

.col-xs-10 {
  width: 83.33333333%; }

.col-xs-9 {
  width: 75%; }

.col-xs-8 {
  width: 66.66666667%; }

.col-xs-7 {
  width: 58.33333333%; }

.col-xs-6 {
  width: 50%; }

.col-xs-5 {
  width: 41.66666667%; }

.col-xs-4 {
  width: 33.33333333%; }

.col-xs-3 {
  width: 25%; }

.col-xs-2 {
  width: 16.66666667%; }

.col-xs-1 {
  width: 8.33333333%; }

@media (min-width: 768px) {
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9 {
    float: left; }
  .col-sm-12 {
    width: 100%; }
  .col-sm-11 {
    width: 91.66666667%; }
  .col-sm-10 {
    width: 83.33333333%; }
  .col-sm-9 {
    width: 75%; }
  .col-sm-8 {
    width: 66.66666667%; }
  .col-sm-7 {
    width: 58.33333333%; }
  .col-sm-6 {
    width: 50%; }
  .col-sm-5 {
    width: 41.66666667%; }
  .col-sm-4 {
    width: 33.33333333%; }
  .col-sm-3 {
    width: 25%; }
  .col-sm-2 {
    width: 16.66666667%; }
  .col-sm-1 {
    width: 8.33333333%; } }

@media (min-width: 1024px) {
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9 {
    float: left; }
  .col-md-12 {
    width: 100%; }
  .col-md-11 {
    width: 91.66666667%; }
  .col-md-10 {
    width: 83.33333333%; }
  .col-md-9 {
    width: 75%; }
  .col-md-8 {
    width: 66.66666667%; }
  .col-md-7 {
    width: 58.33333333%; }
  .col-md-6 {
    width: 50%; }
  .col-md-5 {
    width: 41.66666667%; }
  .col-md-4 {
    width: 33.33333333%; }
  .col-md-3 {
    width: 25%; }
  .col-md-2 {
    width: 16.66666667%; }
  .col-md-1 {
    width: 8.33333333%; } }

@media (min-width: 1280px) {
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9 {
    float: left; }
  .col-lg-12 {
    width: 100%; }
  .col-lg-11 {
    width: 91.66666667%; }
  .col-lg-10 {
    width: 83.33333333%; }
  .col-lg-9 {
    width: 75%; }
  .col-lg-8 {
    width: 66.66666667%; }
  .col-lg-7 {
    width: 58.33333333%; }
  .col-lg-6 {
    width: 50%; }
  .col-lg-5 {
    width: 41.66666667%; }
  .col-lg-4 {
    width: 33.33333333%; }
  .col-lg-3 {
    width: 25%; }
  .col-lg-2 {
    width: 16.66666667%; }
  .col-lg-1 {
    width: 8.33333333%; } }

/* COLORS */
.red {
  color: #ad122a; }

/* TYPOGRAPHY */
@font-face {
  font-family: 'FontAwesome';
  src: url(/fonts/fontawesome-webfont.eot);
  src: url(/fonts/fontawesome-webfont.eot?#iefix&v=4.4.0) format("embedded-opentype"), url(/fonts/fontawesome-webfont.woff2) format("woff2"), url(/fonts/fontawesome-webfont.woff) format("woff"), url(/fonts/fontawesome-webfont.ttf) format("truetype"), url(/fonts/fontawesome-webfont.svg#fontawesomeregular) format("svg");
  font-weight: normal;
  font-style: normal; }

.fa,
.ui-icon {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

body {
  color: #212225;
  font-family: proxima-nova, Helvetica, Arial, sans-serif;
  font-size: 16px; }

h1,
h2,
h3,
h4 {
  color: #545559;
  font-weight: 700;
  line-height: 1.1;
  margin-bottom: 0.5em; }

h1,
.h1 {
  font-size: 28px; }

h2,
.h2 {
  font-size: 20px; }

p,
li {
  line-height: 1.3;
  margin-bottom: 1em; }

li {
  margin-bottom: 0.3em; }

ul,
ol {
  margin-bottom: 1em; }

a {
  color: #0275d8; }

.page-content ul,
.page-content ol {
  margin-left: 40px; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-success {
  color: #3c763d; }

.text-warning {
  color: #8a6d3b; }

.text-error {
  color: #b60000; }

/* LAYOUT */
.content-wrapper,
.section-wrapper {
  margin: 0 auto;
  max-width: 1080px; }

.section-wrapper {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 40px;
  padding-bottom: 40px; }
  @media (min-width: 768px) {
    .section-wrapper {
      padding-left: 35px;
      padding-right: 35px; } }
  @media (min-width: 1024px) {
    .section-wrapper {
      padding-left: 50px;
      padding-right: 50px; } }
  @media (min-width: 1280px) {
    .section-wrapper {
      padding-left: 60px;
      padding-right: 60px; } }
  @media (min-width: 768px) {
    .section-wrapper {
      padding-top: 50px;
      padding-bottom: 50px; } }
  @media (min-width: 1024px) {
    .section-wrapper {
      padding-top: 52px;
      padding-bottom: 52px; } }
  .section-wrapper img {
    max-width: 100%; }

.hidden {
  display: none; }

/* HEADER */
/* HEADER */
header {
  background: #212225;
  color: #fff;
  overflow: hidden; }
  header .content-wrapper {
    padding-left: 20px;
    padding-right: 20px; }
    @media (min-width: 768px) {
      header .content-wrapper {
        padding-left: 35px;
        padding-right: 35px; } }
    @media (min-width: 1024px) {
      header .content-wrapper {
        padding-left: 50px;
        padding-right: 50px; } }
    @media (min-width: 1280px) {
      header .content-wrapper {
        padding-left: 60px;
        padding-right: 60px; } }
  header #logo {
    margin: 10px 0; }

#header-skip-container {
  background: #eee;
  text-align: center;
  width: 100%; }
  #header-skip-container #header-skip {
    color: #333;
    display: inline-block;
    margin-top: -140px;
    padding: 10px;
    position: absolute; }
    #header-skip-container #header-skip:focus {
      text-decoration: underline;
      margin-top: 0;
      position: static; }

#mobile-toggle {
  height: 36px;
  position: absolute;
  right: 15px;
  text-align: left;
  top: 12px;
  width: 32px; }
  #mobile-toggle input {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    cursor: pointer; }
    #mobile-toggle input:checked + span {
      background: transparent; }
      #mobile-toggle input:checked + span:after {
        transform: rotate(45deg) translate(-1px, -8px); }
      #mobile-toggle input:checked + span:before {
        transform: rotate(-45deg) translate(-4px, 12px); }
  #mobile-toggle span {
    position: absolute;
    width: 100%;
    height: 5px;
    background: #ccc;
    margin-top: 10px; }
    #mobile-toggle span:before, #mobile-toggle span:after {
      content: '';
      left: 0;
      position: absolute;
      width: 100%;
      height: 5px;
      background: #ccc;
      top: -9px;
      transition: 0.2s; }
    #mobile-toggle span:after {
      top: 9px; }
  @media (min-width: 1024px) {
    #mobile-toggle {
      display: none; } }

.moto-timestamp-header {
  color: #d3d3d3;
  border-bottom: 1px solid #d3d3d3; }
  .moto-timestamp-header :last-child p {
    text-align: right; }

nav {
  background: #36373c;
  font-family: 'Lato', sans-serif;
  float: none;
  margin: 0 auto;
  overflow: auto;
  padding: 10px 0; }
  nav .content-wrapper {
    padding: 0; }
  nav ul {
    margin: 0; }
    nav ul li {
      list-style-type: none;
      margin: 0; }
      nav ul li a {
        color: #fff;
        display: block;
        font-size: 16px;
        padding: 20px;
        text-decoration: none; }
        nav ul li a:hover, nav ul li a.active {
          background: #ad122a; }
  @media (min-width: 1024px) {
    nav {
      display: block !important;
      margin-top: 0;
      margin-right: 0;
      padding: 0;
      width: auto; }
      nav .content-wrapper {
        padding-left: 20px;
        padding-right: 20px; } }
    @media (min-width: 1024px) and (min-width: 768px) {
      nav .content-wrapper {
        padding-left: 35px;
        padding-right: 35px; } }
    @media (min-width: 1024px) and (min-width: 1024px) {
      nav .content-wrapper {
        padding-left: 50px;
        padding-right: 50px; } }
    @media (min-width: 1024px) and (min-width: 1280px) {
      nav .content-wrapper {
        padding-left: 60px;
        padding-right: 60px; } }
  @media (min-width: 1024px) {
      nav ul {
        display: flex;
        flex-grow: 1; }
        nav ul li {
          display: inline-block;
          max-width: 150px; }
          nav ul li a {
            border-right: 2px solid #212225;
            display: inline-block;
            font-size: 13px;
            padding: 15px 10px;
            text-align: center;
            height: 100%;
            width: 100%; }
          nav ul li:first-child a {
            border-left: 2px solid #212225; } }

/* SECTIONS */
#tool-grid {
  margin-top: 30px;
  margin-bottom: 30px; }
  #tool-grid div {
    margin-bottom: 10px; }
    #tool-grid div a {
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16);
      color: #212225;
      display: block;
      height: 100%;
      padding: 20px 15px;
      text-decoration: none; }
      #tool-grid div a h2 {
        color: #ad122a;
        font-size: 14px;
        margin-bottom: 4px;
        text-decoration: underline; }
      #tool-grid div a p {
        margin-bottom: 5px; }

#weight-conversion {
  margin: 30px 0; }
  #weight-conversion button {
    margin-top: 21px;
    width: 100%; }
  #weight-conversion p {
    margin-top: 15px; }

#wiring-guide {
  border: 1px solid #aaa;
  border-radius: 4px;
  margin-top: 20px;
  max-width: 320px;
  padding: 15px 20px; }
  #wiring-guide span {
    display: inline-block;
    width: calc(33.3333% - 5px); }
    #wiring-guide span strong {
      font-size: 22px; }
    #wiring-guide span:nth-of-type(2) {
      text-align: center; }
    #wiring-guide span:nth-of-type(3) {
      text-align: right; }
  #wiring-guide label {
    color: #212225;
    font-size: 16px;
    font-weight: normal;
    margin-left: 15px;
    margin-right: 15px; }
  #wiring-guide input {
    box-shadow: none;
    text-align: center;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 10px;
    width: calc(100% - 30px); }
  #wiring-guide .green {
    background: #0f6e1f;
    color: #fff; }
  #wiring-guide .black {
    background: #000;
    color: #fff; }
  #wiring-guide .red {
    background: #ad122a;
    color: #fff; }
  #wiring-guide .blue {
    background: #0e2df5;
    color: #fff; }
  #wiring-guide .brown {
    background: #5d3100;
    color: #fff; }
  #wiring-guide .yellow {
    background: #ffff00;
    color: #000; }
  #wiring-guide .grey {
    background: #d3d3d3;
    color: #fff; }
  #wiring-guide .pink {
    background: #ffc0cb;
    color: #000; }
  #wiring-guide .orange {
    background: #ffa500;
    color: #fff; }
  #wiring-guide .bare {
    font-size: 22px;
    padding: 2px 10px; }
  @media (min-width: 768px) {
    #wiring-guide {
      margin-top: 0; } }

#graduation {
  margin: 40px 0;
  max-width: 600px; }
  #graduation h2 {
    color: #ad122a;
    font-weight: normal; }
  #graduation .col-section + h2 {
    margin-top: 25px; }
  #graduation button {
    width: 100%; }
  #graduation #graduation-result {
    border: 1px solid #aaa;
    border-radius: 4px;
    margin-top: 40px;
    padding: 10px; }
    #graduation #graduation-result #graduation-formula {
      text-align: center; }
      #graduation #graduation-result #graduation-formula p:first-of-type {
        border-bottom: 1px solid #000;
        margin-bottom: 2px; }
    #graduation #graduation-result #graduation-total {
      align-self: center; }
      #graduation #graduation-result #graduation-total h3 {
        font-size: 22px;
        text-align: center; }
        #graduation #graduation-result #graduation-total h3 span {
          margin-right: 15px; }
    #graduation #graduation-result .small {
      color: #36373c;
      font-size: 12px;
      line-height: 1.1em;
      margin-top: 15px; }
  @media (min-width: 768px) {
    #graduation .reset-btn {
      text-align: left; } }

#tolerances button {
  width: 100%; }

@media (min-width: 768px) {
  #tolerances .reset-btn {
    text-align: left; } }

#tolerances-table {
  margin-top: 50px; }
  @media (min-width: 768px) {
    #tolerances-table td {
      text-align: center; } }

.result-table {
  font-size: 14px;
  width: 100%; }
  .result-table th,
  .result-table td {
    padding: 10px; }
  .result-table td {
    border-bottom: 1px solid #ebebeb; }
  .result-table th {
    background: #36373c;
    color: #fff; }

.calc-table {
  margin-bottom: 40px; }
  @media (min-width: 768px) {
    .calc-table th,
    .calc-table td {
      padding: 12px 20px;
      text-align: left; }
    .calc-table th:last-of-type,
    .calc-table td:last-of-type {
      text-align: right; }
    .calc-table td {
      color: #707070;
      font-weight: bold; }
    .calc-table td:first-of-type {
      color: #36373c;
      font-weight: normal; } }

@media (max-width: 767px) {
  .responsive-table th {
    display: none; }
  .responsive-table td {
    display: block;
    overflow: hidden;
    padding: 10px 0;
    text-align: right; }
    .responsive-table td:before {
      content: attr(data-th) ": ";
      display: block;
      float: left;
      font-weight: bold;
      padding-right: 10px;
      text-align: left;
      width: 50%; }
    .responsive-table td:last-of-type {
      border-color: #36373c;
      margin-bottom: 25px; } }

#product-grid {
  margin-top: 30px; }
  #product-grid div {
    margin-bottom: 30px; }
    #product-grid div a {
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16);
      color: #212225;
      display: block;
      height: 100%;
      padding: 20px 15px 50px 15px;
      position: relative;
      text-decoration: none; }
      #product-grid div a h2 {
        color: #ad122a;
        font-size: 14px;
        margin-bottom: 6px; }
      #product-grid div a p {
        font-size: 12px;
        margin-bottom: 15px; }
      #product-grid div a button {
        border-radius: 8px;
        font-size: 10px;
        font-weight: 600;
        padding: 10px 12px;
        position: absolute;
        bottom: 20px;
        width: calc(100% - 30px); }
  @media (min-width: 1024px) {
    #product-grid {
      margin-left: -30px;
      margin-right: -30px; }
      #product-grid div {
        padding: 0 30px; } }

#livestock-calc {
  margin-bottom: 80px; }
  #livestock-calc .product-card {
    margin-bottom: 30px; }
    #livestock-calc .product-card a {
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16);
      color: #212225;
      display: block;
      padding: 20px 15px;
      text-decoration: none; }
      #livestock-calc .product-card a h2 {
        color: #ad122a;
        font-size: 14px;
        margin-bottom: 6px; }
      #livestock-calc .product-card a p {
        font-size: 12px;
        margin-bottom: 15px; }
      #livestock-calc .product-card a button {
        border-radius: 8px;
        font-size: 10px;
        font-weight: 600;
        padding: 10px 12px;
        width: 100%; }
  #livestock-calc button,
  #livestock-calc .btn {
    border-radius: 8px;
    font-size: 10px;
    font-weight: 600;
    padding: 10px 12px;
    text-align: center;
    width: 100%; }
  #livestock-calc #livestock-result h3 {
    font-size: 14px; }
  #livestock-calc #livestock-result div {
    border: 1px solid #707070;
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 20px 20px 5px 20px;
    text-align: center; }
    #livestock-calc #livestock-result div p {
      font-size: 12px; }
    #livestock-calc #livestock-result div h2 {
      font-size: 56px; }
  #livestock-calc .btn-grey {
    display: block;
    margin: 0 auto;
    width: calc(100% - 40px); }
  @media (min-width: 1024px) {
    #livestock-calc .product-card {
      padding-right: 40px; } }

#livestock-button-header div:first-child {
  padding-bottom: 40px; }

#livestock-button-header .btn {
  border-radius: 8px;
  font-size: 10px;
  font-weight: 600;
  padding: 10px 40px;
  position: relative; }

#livestock-button-header .btn:first-of-type {
  padding: 10px 40px 10px 60px; }
  #livestock-button-header .btn:first-of-type img {
    left: 30px;
    margin-top: -4px;
    position: absolute; }

@media (min-width: 768px) {
  #livestock-button-header div:first-child {
    order: 2;
    padding-bottom: 20px;
    text-align: right; } }

/* FOOTER */
footer {
  background: #212225;
  color: #fff;
  padding: 30px 0 50px;
  text-align: center; }

/* FORMS */
label {
  color: #707070;
  display: block;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 4px; }

.radio,
.checkbox {
  display: block;
  margin-bottom: 8px;
  position: relative; }

.radio label,
.checkbox label {
  min-height: 20px;
  padding: 0 20px;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer; }

input[type='text'],
input[type='email'],
input[type='password'],
input[type='date'],
input[type='tel'],
input[type='number'],
textarea,
select {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  font-family: proxima-nova, Helvetica, Arial, sans-serif;
  font-size: 14px;
  margin-bottom: 20px;
  padding: 6px 10px;
  width: 100%; }

input[type='text']:focus,
input[type='email']:focus,
input[type='password']:focus,
input[type='date']:focus,
input[type='tel']:focus,
input[type='number']:focus,
textarea:focus,
select:focus {
  border-color: #66afe9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  outline: 0; }

input[type='text'][disabled],
input[type='email'][disabled],
input[type='password'][disabled],
input[type='date'][disabled],
input[type='tel'][disabled],
input[type='number'][disabled],
textarea[disabled],
select[disabled] {
  cursor: not-allowed;
  background-color: #eee; }

.checkbox input[type='checkbox'],
.radio input[type='radio'] {
  position: absolute;
  margin: 1px 0 0;
  margin-left: -20px; }

input[type='text']::placeholder,
input[type='email']::placeholder,
input[type='password']::placeholder,
input[type='date']::placeholder,
input[type='tel']::placeholder,
input[type='number']::placeholder,
textarea::placeholder {
  color: #eee; }

select {
  background-image: url(/e6f4ce75e51fcbfa7184303c09e9a5da.png);
  background-repeat: no-repeat;
  background-position: center left;
  background-size: auto 100%;
  background-color: #eee;
  padding-left: 50px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

input[type='checkbox'] {
  -webkit-appearance: none;
  background: #fff;
  border: 2px solid #36373c;
  border-radius: 3px;
  position: absolute;
  height: 20px;
  width: 20px; }
  input[type='checkbox']:after {
    color: #000;
    content: '';
    display: inline-block;
    font-size: 27px;
    font-weight: 300;
    height: 20px;
    position: absolute;
    left: 1px;
    top: -10px; }
  input[type='checkbox']:checked:after {
    content: '\d7'; }
  input[type='checkbox']:focus {
    border-color: #0275d8;
    outline: none; }
  input[type='checkbox'] + label {
    display: inline-block;
    margin-bottom: 25px;
    padding-left: 30px;
    position: relative;
    vertical-align: top; }

button,
.btn {
  background: #ad122a;
  border: 0;
  border-radius: 3px;
  color: #fff;
  display: inline-block;
  font-size: 13px;
  font-weight: 600;
  padding: 7px 12px; }

.btn-full {
  width: 100%; }

a.btn {
  text-decoration: none; }

.btn-grey {
  background: #545559;
  color: #fff; }

.form-help {
  color: #888;
  font-size: 80%;
  margin: 5px 0 10px; }

.reset-btn {
  background: none;
  color: #36373c;
  text-decoration: underline; }

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield; }

.moto-col {
  margin-top: 1.75em; }

.moto-result-container {
  display: flex;
  justify-content: center; }

.moto-result-row {
  display: flex;
  justify-content: space-evenly;
  width: 75%; }
  @media (max-width: 768px) {
    .moto-result-row {
      width: 100%;
      flex-direction: column;
      align-items: center; } }

.moto-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 30%;
  min-width: 30%;
  text-align: center; }
  .moto-block :last-child {
    font-weight: 900;
    font-size: xx-large; }

.moto-red {
  color: #ad122a; }

.moto-green {
  color: green; }

.moto-grey {
  color: #36373c; }

.moto-input-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px; }
  .moto-input-wrapper input {
    margin-bottom: 0; }

.moto-input-units {
  background-color: #36373c;
  color: white;
  width: 100px;
  height: 30px;
  margin-left: -2px;
  display: flex;
  align-items: center;
  justify-content: center; }

.show-print {
  display: none; }

.result-image {
  display: flex;
  justify-content: center;
  padding-top: 35px; }

@media print {
  header,
  footer,
  .hide-print {
    display: none; }
  .show-print {
    display: flex; }
  #page-main {
    padding: 0;
    margin: 55px 0 0; }
  .print-xs-4 {
    width: 25%; }
  .print-xs-6 {
    width: 50%; }
  .print-xs-8 {
    width: 75%; }
  .print-xs-12 {
    width: 50%; }
  .moto-input-units {
    color: black;
    background-color: white;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16);
    border-radius: 3px;
    box-sizing: border-box;
    border: 1px solid #ccc; }
  .moto-block {
    width: 200px; }
  .moto-result-row {
    display: contents; }
  .col-no-margin {
    margin-left: 0;
    margin-right: 0; }
  #report-table {
    -webkit-print-color-adjust: exact !important; } }

#report-table {
  width: 100%; }
  #report-table th {
    color: white;
    background-color: black; }
  #report-table th:first-child {
    text-align: left;
    padding: 5px 5px; }
  #report-table tr.result-row {
    border-bottom: 1px solid #36373c; }
    #report-table tr.result-row td {
      padding: 5px 5px; }
    #report-table tr.result-row td:first-child {
      text-align: left;
      border-right: 1px solid #36373c; }
    #report-table tr.result-row td:last-child {
      text-align: center; }

.field-error {
  font-size: 0.75rem; }

#truck-scale-calculator-list {
  margin-top: 30px; }
  #truck-scale-calculator-list div {
    margin-bottom: 30px; }
    #truck-scale-calculator-list div a {
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16);
      color: #212225;
      display: block;
      height: 100%;
      padding: 20px 15px 50px 15px;
      position: relative;
      text-decoration: none; }
      #truck-scale-calculator-list div a h2 {
        color: #ad122a;
        font-size: 14px;
        margin-bottom: 6px; }
      #truck-scale-calculator-list div a p {
        font-size: 12px;
        margin-bottom: 15px; }
      #truck-scale-calculator-list div a button {
        border-radius: 8px;
        font-size: 10px;
        font-weight: 600;
        padding: 10px 12px;
        position: absolute;
        bottom: 20px;
        width: calc(100% - 30px); }
  @media (min-width: 1024px) {
    #truck-scale-calculator-list {
      margin-left: -30px;
      margin-right: -30px; }
      #truck-scale-calculator-list div {
        padding: 0 30px; } }

#truck-scale-calculator img {
  height: 100%;
  width: 300px;
  object-fit: contain; }

#truck-scale-calculator p {
  color: #9b9a9a;
  display: none; }

#truck-scale-calculator button, #truck-scale-calculator .btn {
  border-radius: 8px;
  font-size: 10px;
  font-weight: 600;
  padding: 10px 12px;
  text-align: center;
  width: 100%; }
  #truck-scale-calculator button[data-disabled="true"], #truck-scale-calculator .btn[data-disabled="true"] {
    background-color: gray;
    color: darkgray;
    cursor: not-allowed;
    pointer-events: none; }

#truck-scale-calculator .form-field {
  margin-bottom: 20px; }

@media (min-width: 1024px) {
  #truck-scale-calculator p {
    display: block; }
  #truck-scale-calculator #header {
    display: block; } }

#truck-scale-calculator #header {
  display: flex; }

#truck-scale-calculator #maximum-speed-calculator p {
  display: block; }

#truck-scale-calculator #maximum-speed-calculator #min-time {
  color: #9b9a9a; }

#truck-scale-calculator #maximum-speed-calculator #header {
  place-self: start; }

#truck-scale-calculator #maximum-speed-calculator input {
  margin-bottom: 5px; }

#truck-scale-calculator #maximum-speed-calculator form {
  width: 100%; }

@media (min-width: 1024px) {
  #truck-scale-calculator #maximum-speed-calculator {
    display: flex;
    flex-grow: 1; }
    #truck-scale-calculator #maximum-speed-calculator p {
      display: none; }
    #truck-scale-calculator #maximum-speed-calculator form {
      padding-left: 15px;
      padding-right: 15px;
      width: 100%;
      max-width: 500px; } }

#truck-scale-calculator #maximum-speed-calculator #maximum-speed-result {
  margin-bottom: 20px; }
  #truck-scale-calculator #maximum-speed-calculator #maximum-speed-result .legal-for-trade-speed {
    color: green; }
  #truck-scale-calculator #maximum-speed-calculator #maximum-speed-result .not-legal-for-trade-speed {
    color: #ad122a; }
  #truck-scale-calculator #maximum-speed-calculator #maximum-speed-result h3 {
    font-size: 14px; }
  #truck-scale-calculator #maximum-speed-calculator #maximum-speed-result div {
    border: 1px solid #707070;
    border-radius: 4px;
    padding: 15px;
    text-align: center; }
    #truck-scale-calculator #maximum-speed-calculator #maximum-speed-result div p {
      font-size: 13px;
      display: block; }
    #truck-scale-calculator #maximum-speed-calculator #maximum-speed-result div h2 {
      font-size: 40px;
      margin-bottom: 5px; }
    #truck-scale-calculator #maximum-speed-calculator #maximum-speed-result div h3 {
      margin-bottom: 25px;
      color: #9b9a9a; }

#truck-scale-calculator #carbon-footprint-calculator button:last-child, #truck-scale-calculator #carbon-footprint-calculator .btn:last-child {
  margin-top: 5px; }

#truck-scale-calculator #carbon-footprint-calculator #header {
  place-self: start; }

#truck-scale-calculator #carbon-footprint-calculator input {
  margin-bottom: 5px; }

#truck-scale-calculator #carbon-footprint-calculator form {
  padding: 0;
  margin-bottom: 10px;
  margin-top: 10px; }

#truck-scale-calculator #carbon-footprint-calculator p {
  display: block; }

@media (min-width: 1024px) {
  #truck-scale-calculator #carbon-footprint-calculator button, #truck-scale-calculator #carbon-footprint-calculator .btn {
    margin: 0; }
  #truck-scale-calculator #carbon-footprint-calculator form {
    padding: 15px;
    margin-top: 0; }
  #truck-scale-calculator #carbon-footprint-calculator p {
    display: none; } }

@media (min-width: 1024px) {
  #truck-scale-calculator #carbon-footprint-calculator #carbon-footprint-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); } }

#truck-scale-calculator #carbon-footprint-calculator #result {
  margin-top: 10px;
  margin-bottom: 20px; }
  #truck-scale-calculator #carbon-footprint-calculator #result h3 {
    margin-bottom: 5px; }
  #truck-scale-calculator #carbon-footprint-calculator #result #results {
    display: flex;
    flex-direction: column;
    gap: 10px; }
    #truck-scale-calculator #carbon-footprint-calculator #result #results .container {
      width: 100%;
      text-align: center; }
      #truck-scale-calculator #carbon-footprint-calculator #result #results .container div {
        background-color: #545151;
        height: 50px; }
      #truck-scale-calculator #carbon-footprint-calculator #result #results .container h2 {
        font-size: 32px;
        margin-top: 10px;
        color: #ad122a; }
      #truck-scale-calculator #carbon-footprint-calculator #result #results .container .carbon-reduction {
        color: green; }
      #truck-scale-calculator #carbon-footprint-calculator #result #results .container h4 {
        color: white;
        padding: 10px 40px;
        height: 100%; }
      @media (min-width: 1024px) {
        #truck-scale-calculator #carbon-footprint-calculator #result #results .container {
          width: 33%; }
          #truck-scale-calculator #carbon-footprint-calculator #result #results .container h4 {
            font-size: 13px; } }
    @media (min-width: 1024px) {
      #truck-scale-calculator #carbon-footprint-calculator #result #results {
        flex-direction: row; } }

#truck-scale-calculator #transaction-capacity-calculator {
  margin-bottom: 10px; }
  #truck-scale-calculator #transaction-capacity-calculator img {
    width: 800px; }
  #truck-scale-calculator #transaction-capacity-calculator p {
    display: block; }
  #truck-scale-calculator #transaction-capacity-calculator input {
    margin-bottom: 0; }
  #truck-scale-calculator #transaction-capacity-calculator form {
    width: 100%; }
  #truck-scale-calculator #transaction-capacity-calculator .legal-for-trade-speed {
    color: green; }
  #truck-scale-calculator #transaction-capacity-calculator #side-results-mobile {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px; }
    @media (min-width: 1024px) {
      #truck-scale-calculator #transaction-capacity-calculator #side-results-mobile {
        display: none; } }
  #truck-scale-calculator #transaction-capacity-calculator #side-results {
    display: none; }
    @media (min-width: 1024px) {
      #truck-scale-calculator #transaction-capacity-calculator #side-results {
        display: block;
        margin-top: 20px; } }
  @media (min-width: 1024px) {
    #truck-scale-calculator #transaction-capacity-calculator {
      display: flex;
      flex-grow: 1; }
      #truck-scale-calculator #transaction-capacity-calculator p {
        display: none; }
      #truck-scale-calculator #transaction-capacity-calculator form {
        padding-left: 15px;
        padding-right: 15px;
        width: 100%;
        max-width: 450px; }
      #truck-scale-calculator #transaction-capacity-calculator #header h2 {
        margin-top: 5px; } }
  #truck-scale-calculator #transaction-capacity-calculator #transaction-capacity-result {
    margin-bottom: 20px;
    width: 100%; }
    #truck-scale-calculator #transaction-capacity-calculator #transaction-capacity-result h3 {
      font-size: 14px; }
    #truck-scale-calculator #transaction-capacity-calculator #transaction-capacity-result div {
      border: 1px solid #707070;
      border-radius: 4px;
      padding: 15px;
      text-align: center; }
      #truck-scale-calculator #transaction-capacity-calculator #transaction-capacity-result div p {
        font-size: 13px;
        display: block; }
      #truck-scale-calculator #transaction-capacity-calculator #transaction-capacity-result div h2 {
        font-size: 40px;
        margin-bottom: 20px;
        color: green; }
      #truck-scale-calculator #transaction-capacity-calculator #transaction-capacity-result div h3 {
        font-size: 20px;
        margin-bottom: 25px;
        color: #9b9a9a; }
    @media (min-width: 1024px) {
      #truck-scale-calculator #transaction-capacity-calculator #transaction-capacity-result {
        max-width: 300px; } }

#truck-scale-report button, #truck-scale-report .btn {
  border-radius: 8px;
  min-width: 225px;
  text-align: center; }

#truck-scale-report h1 {
  color: black;
  font-size: 30px; }

#truck-scale-report p {
  color: #9b9a9a;
  text-align: center; }

#truck-scale-report table {
  margin: 20px 0;
  font-weight: bold; }
  #truck-scale-report table tr {
    border-bottom: 1px solid black; }
  #truck-scale-report table th {
    background-color: black;
    color: white;
    min-width: 200px;
    padding: 15px;
    text-align: center;
    width: 100%; }
    #truck-scale-report table th:first-child {
      text-align: left; }
  #truck-scale-report table td {
    padding: 10px 5px;
    text-align: center; }
    #truck-scale-report table td:first-child {
      text-align: left;
      border-right: 1px solid black; }

#truck-scale-report .button-container {
  display: flex;
  justify-content: end; }

#truck-scale-report #return-button {
  background-color: #9b9a9a; }

@media print {
  #truck-scale-report .no-print {
    display: none; } }
